import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProductServices } from "../../services/productServices.service";

export const getProductServicesThunk = createAsyncThunk(
  "api/get-order-page",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getProductServices();
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);
