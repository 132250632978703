/* eslint-disable multiline-ternary */
// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getProductServicesThunk } from "./productServices.thunk";

export const dummyData = [
  {
    value: "culling",
    label: "Culling",
    type: "radio",
    order: 1,
    note: "<b>Note : </b><i>The majority of our team primarily uses Adobe Premiere for standard editing services. The other two programs are exclusively available for culling purposes and cannot be guaranteed for use in full editing projects.</i>",
    option: [
      {
        label: "Adobe Premiere",
        value: "adobe_premiere",
        price: "349",
        carat: "28",
        order: 1,
        info: '<p><a href="https://vimeo.com/1043153311" rel="noopener noreferrer" target="_blank"><strong><u>View Demo</u></strong></a></p>',
        remarks: null,
      },
      {
        label: "Davinci Resolve",
        value: "davinci_resolve",
        price: "349",
        carat: "28",
        order: 2,
        info: '<p><a href="https://vimeo.com/1043153391" rel="noopener noreferrer" target="_blank"><strong><u>View Demo</u></strong></a></p>',
        remarks: "*",
      },
      {
        label: "FCPX",
        value: "fcpx",
        price: "349",
        carat: "28",
        order: 3,
        info: '<p><a href="https://vimeo.com/1043153372" rel="noopener noreferrer" target="_blank"><strong><u>View Demo</u></strong></a></p>',
        remarks: "*",
      },
    ],
  },
  {
    value: "cinematic_film",
    label: "Cinematic Feature Film \u2013 Music + Dialogue",
    type: "check-box",
    order: 5,
    option: [
      {
        label: "13-15 Minutes",
        value: "13_15_minute",
        price: "799",
        carat: "64",
        order: 1,
        info: null,
      },
      {
        label: "16-20 Minutes",
        value: "16_20_minute",
        price: "879",
        carat: "70",
        order: 2,
        info: null,
      },
      {
        label: "21-25 Minutes",
        value: "21_25_minute",
        price: "989",
        carat: "79",
        order: 3,
        info: null,
      },
    ],
  },
  {
    value: "insta_video",
    label: "Instagram Video",
    type: "check-box",
    order: 2,
    option: [
      {
        label: "1 Minute",
        value: "1_minute",
        price: "249",
        carat: "20",
        order: 1,
        info: null,
      },
    ],
  },
  {
    value: "highlight_video",
    label: "Highlight Video",
    type: "check-box",
    order: 3,
    option: [
      {
        label: "2-3 Minutes",
        value: "2_3_minute",
        price: "399",
        carat: "32",
        order: 1,
        info: null,
      },
      {
        label: "4-6 Minutes",
        value: "4_6_minute",
        price: "499",
        carat: "40",
        order: 2,
        info: null,
      },
    ],
  },
  {
    value: "narrative_highlight_video",
    label: "Narrative Highlight Video",
    type: "check-box",
    order: 4,
    option: [
      {
        label: "3-5 Minutes",
        value: "3_5_minute",
        price: "599",
        carat: "48",
        order: 1,
        info: null,
      },
      {
        label: "6-8 Minutes",
        value: "6_8_minute",
        price: "699",
        carat: "53",
        order: 2,
        info: null,
      },
      {
        label: "9-12 Minutes",
        value: "9_12_minute",
        price: "739",
        carat: "59",
        order: 3,
        info: null,
      },
    ],
  },
  {
    value: "documentary_edits",
    label: "Documentary Edits",
    type: "check-box",
    order: 6,
    option: [
      {
        label: "Ceremony MCE",
        value: "ceremony_MCE",
        price: "99",
        carat: "8",
        order: 1,
        info: null,
      },
      {
        label: "Reception MCE",
        value: "reception_MCE",
        price: "99",
        carat: "8",
        order: 2,
        info: null,
      },
      {
        label: "Dances Only",
        value: "dances_only",
        price: "75",
        carat: "6",
        order: 3,
        info: null,
      },
      {
        label: "Speeches Only",
        value: "speeches_only",
        price: "75",
        carat: "6",
        order: 4,
        info: null,
      },
      {
        label: "Ceremony + Reception MCE",
        value: "ceremony_reception_MCE",
        price: "149",
        carat: "12",
        order: 5,
        info: null,
      },
      {
        label: "Full Day Edit",
        value: "full_day_edit",
        price: "249",
        carat: "20",
        order: 6,
        info: null,
      },
    ],
  },
];

const initialState = {
  isFetching: false,
  data: {
    productList: [],
    productMappedList: [],
  },
  error: null,
};

const getProductServicesSlice = createSlice({
  name: "productServices",
  initialState,
  // reducers: {
  //   loadingState: (state, action) => {
  //     state.isFetching = action.payload;
  //   },
  // },
  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getProductServicesThunk.pending, (state) => {
      state.data = initialState.data;
      state.isFetching = true;
    });

    builder.addCase(getProductServicesThunk.fulfilled, (state, action) => {
      const list_data =
        action?.payload?.order_page_data?.column_sizes
          // dummyData
          .map((item) => ({
            ...item,
            option: item.option
              ? [...item.option].sort((a, b) => a.order - b.order)
              : [],
          }))
          .sort((a, b) => a.order - b.order) || [];

      const convertedData = list_data.flatMap(
        ({
          value: parentValue,
          label: parentLabel,
          order: parentOrder,
          type: parentType,
          option,
          ...parentRest
        }) =>
          option.map(
            ({
              value: childValue,
              label: childLabel,
              order: childOrder,
              price,
              carat,
              ...childReset
            }) => ({
              value: `${parentValue}:${childValue}`,
              label: `${parentLabel} : ${childLabel}`,
              label_2: `${parentLabel} (${childLabel})`,
              type: parentType,
              childLabel,
              childValue,
              parentLabel,
              parentValue,
              order: Number(`${parentOrder}${childOrder}`),
              parentOrder,
              childOrder,
              price,
              carat,
              rest: {
                parent: parentRest,
                child: childReset,
              },
            })
          )
      );

      state.isFetching = false;
      state.data.productList = list_data;
      state.data.productMappedList = convertedData;
      state.error = null;
    });

    builder.addCase(getProductServicesThunk.rejected, (state, action) => {
      state.isFetching = false;
      state.error = action?.payload?.message;
    });
  },
});

// export const { loadingState } = getProductServicesSlice.actions;

export default getProductServicesSlice.reducer;
